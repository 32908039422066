import Button from 'react-bootstrap/Button';

function sendMessage(message, token, dockId) {
  fetch("/event", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token,
    },
    body: JSON.stringify({
      "event": message,
      "dockId": dockId,
    })
  })
    .then((response) => {
      const el = document.getElementById('AppCodeSendStatus')

      if(response.status === 201) {
        el.innerHTML = "<h5>Success</h5>Fire alarm triggered"
      } else {
        el.innerHTML = "<h5>Failure</h5>Failed to trigger file alarm"
      }
      el.className = 'show'
      el.style.visibility = "visible"
      setTimeout(() => {
        el.className = el.className.replace('show', '')
        el.style.visibility = "hidden"
      }, 3000)
    })
    .then((data) => {
      if (data) {
        console.log(data);
      }
    })
    .catch((error) => console.log(error));
}

export default function Events({token, selectedDock}) {


  const handleInputText = () => {
    sendMessage("trigger_fire_alarm", token, selectedDock)
  };

  return (
    <div>
          <div id="EventsTriggerFireAlarm" style={{
            visibility: "hidden",
            minWidth: "150px",
            marginLeft: "-125px",
            backgroundColor: "#333",
            color: "#fff",
            textAlign: "center",
            borderRadius: "2px",
            padding: "16px",
            position: "fixed",
            zIndex: 1,
            right: "5%",
            bottom: "30px",
            fontSize: "17px",
            fontFamily: "'Helvetica', sans-serif",
          }}/>
          <Button variant="primary" className="align-self-stretch" style={{width: "50%", margin:"20px"}} onClick={() => handleInputText()}>Trigger Fire Alarm</Button>{' '}
    </div>
  );
}
